exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-galerija-js": () => import("./../../../src/pages/galerija.js" /* webpackChunkName: "component---src-pages-galerija-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-o-ordinaciji-js": () => import("./../../../src/pages/o-ordinaciji.js" /* webpackChunkName: "component---src-pages-o-ordinaciji-js" */),
  "component---src-pages-o-osnivacu-js": () => import("./../../../src/pages/o-osnivacu.js" /* webpackChunkName: "component---src-pages-o-osnivacu-js" */),
  "component---src-pages-terapijske-usluge-endodoncija-js": () => import("./../../../src/pages/terapijske-usluge/endodoncija.js" /* webpackChunkName: "component---src-pages-terapijske-usluge-endodoncija-js" */),
  "component---src-pages-terapijske-usluge-izbeljivanje-js": () => import("./../../../src/pages/terapijske-usluge/izbeljivanje.js" /* webpackChunkName: "component---src-pages-terapijske-usluge-izbeljivanje-js" */),
  "component---src-pages-terapijske-usluge-konzervativna-stomatologija-js": () => import("./../../../src/pages/terapijske-usluge/konzervativna-stomatologija.js" /* webpackChunkName: "component---src-pages-terapijske-usluge-konzervativna-stomatologija-js" */),
  "component---src-pages-terapijske-usluge-ortodoncija-js": () => import("./../../../src/pages/terapijske-usluge/ortodoncija.js" /* webpackChunkName: "component---src-pages-terapijske-usluge-ortodoncija-js" */),
  "component---src-pages-terapijske-usluge-pregled-js": () => import("./../../../src/pages/terapijske-usluge/pregled.js" /* webpackChunkName: "component---src-pages-terapijske-usluge-pregled-js" */),
  "component---src-pages-terapijske-usluge-protetika-js": () => import("./../../../src/pages/terapijske-usluge/protetika.js" /* webpackChunkName: "component---src-pages-terapijske-usluge-protetika-js" */),
  "component---src-pages-terapijske-usluge-terapija-donjovilicnog-zgloba-js": () => import("./../../../src/pages/terapijske-usluge/terapija-donjovilicnog-zgloba.js" /* webpackChunkName: "component---src-pages-terapijske-usluge-terapija-donjovilicnog-zgloba-js" */)
}

